/* FOR Smartphone Landscape & Portrait */
@media (max-width:767px){
	html,
	body,
	#root {
		// height: auto; //  !important
		// OPTION: FOR "bounce" effects or refreshes
		// https://developer.mozilla.org/en-US/docs/Web/CSS/overscroll-behavior
		overscroll-behavior: none; // contain
	}

	.lockScroll{
		overflow:hidden !important;
	}
	
	// Landing Page:
	// .landing-page .hr-v{
	// 	width: 100%;
	// 	margin: .5rem 0;
	// }

	// .landing-page .card > img
	// .landing-page > img 
	.img-landing-page {
		margin: 1.5rem 0 0 1.5rem;
	}

	// .landing-page .hr-v::before, 
	// .landing-page .hr-v::after {
	// 	width: auto;
	// 	background-color: transparent;
	// 	border-top: 2px solid #dee2e6;
	// 	margin: 0;
	// }

	// .landing-page .hr-v::before {
	// 	margin-right: 9px;
	// }
	// .landing-page .hr-v::after {
	// 	margin-left: 9px;
	// }

	// Helpers:
	// .ws-pre-s-lp {
	// 	white-space: pre;
	// }

	// Sizing:
	// .w-s-lp-75 {
	// 	width: 
	// }

	// antd:
	// <Table />
	// .ant-table-wrapper
	.ant-table {
		width: 100%;
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
		// OPTION: FOR "bounce" effects or refreshes
		// https://developer.mozilla.org/en-US/docs/Web/CSS/overscroll-behavior
		// overscroll-behavior: none; // contain
	}
	
	

}/* END Smartphone Landscape & Portrait */