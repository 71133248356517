﻿//
// antd:
// OVERRIDE metronic:
.anticon svg {
  vertical-align: initial;
}

.ant-btn {
  border-radius: 0.42rem;
}

// Antd: <Skeleton />
// .ant-skeleton{
//  cursor: wait;
// }

/*.fs-skeleton-grid .ant-skeleton-paragraph {
	display: flex;
}

.fs-skeleton-grid .ant-skeleton-paragraph > * + * {
	margin-top: 0 !important;
	margin-left: 15px;
}*/
// END Antd: <Skeleton />

// Select:
.ant-select > .ant-select-selector:not(.ant-select-focused) {
  border-color: #E4E6EF !important;
}

.ant-select > .ant-select-selector {
  border-radius: 0.28rem !important;
}

// Table size:
.fsys-table .ant-table-small .ant-table-tbody {
  font-size: .95rem;
}
// .ant-table.ant-table-small .ant-table-thead > tr > th
.fsys-table .ant-table-small .ant-table-thead > tr > th {
  padding-top: 10px;
  padding-bottom: 10px;
  // background-color: #EEF0F8;
}

.fsys-table .ant-table.ant-table-small .ant-table-thead .ant-table-filter-column {
  margin: 0;
}

.fsys-table .ant-table-filter-trigger {
  margin-left: auto;
}

.fsys-table .ant-table-filter-trigger .anticon {
  position: static;
  transform: none;
}

.fsys-table .ant-table-pagination {
  padding-right: .75rem;
  padding-left: .75rem;
}

.ant-table-filter-trigger.active:not(:hover) {
  background-color: $hover-color; // $info | $success
  box-shadow: inset 0 0 0 1px rgba(38,143,255,.5);
}

// Transfer:
// Set equal width (ellipsis items)
/*.fsys-transfer.equal-width .ant-transfer-list{
  width: 100%;// auto
  flex: auto;
  overflow: hidden;
  // text-overflow: ellipsis;
  // white-space: nowrap;
}*/

// Loading:
// .transfer-loading .ant-transfer-list
// .transfer-loading .ant-transfer-list-body-not-found{
//   flex: auto;
//   position: relative;
//   cursor: progress;
// }
// .transfer-loading .ant-empty{
//   display: none;
// }
// // .transfer-loading .ant-transfer-list::after
// .transfer-loading .ant-transfer-list-body-not-found::after{
//   content: "";// LOADING
//   // display: flex;
//   // justify-content: center;
//   // align-items: center;
//   // background-color: rgba(255,255,255,.3);
//   position: absolute;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   margin: auto;
//   z-index: 1;

//   display: inline-block;
//   width: 2rem;
//   height: 2rem;
//   // vertical-align: text-bottom;
//   border: .25em solid currentColor;
//   border-right-color: transparent;
//   border-radius: 50%;
//   color: #007bff;
//   -webkit-animation: spinner-border .75s linear infinite;
//   animation: spinner-border .75s linear infinite;

//   // cursor: progress;
// }

.ant-transfer-list {
  background-color: #fff;
}

.ant-transfer-list-body-search-wrapper {
  border-bottom: 1px solid #f0f0f0;
}

// antd-img-crop
.antd-img-crop-modal .ant-modal-body{
	padding: 0 0 16px;
}
// END antd-img-crop

// <Tree />
.rc-tree-child-tree {
  display: block;
}

.node-motion {
  transition: all .3s;
  overflow-y: hidden;
}

