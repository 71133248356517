﻿/* 
  Device = Laptops, Desktops
  Screen = B/w 1025px to 1280px
*/
// (min-width: 1281px)
@media (min-width: 1025px) and (max-width: 1280px) {
  // Show children on hover parent
  .hover-child-show:not(:hover) .hover-show {
    display: none;
  }
  
  

}