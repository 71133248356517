// Ava
.img-frame,
.ava-frame
// .ava
{
	display: inline-block;
	// margin:0;// IF USE figure
	// overflow: hidden;
}
.ava {
	font-size: 0;
	display: block;
	object-fit: cover;
	overflow: hidden;
}

/*.ava[aria-label]{
	position:relative;
}*/

.ava[aria-label]::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	content: attr(aria-label);
	color: currentColor;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	text-transform: uppercase;
	font-size: var(--fs);
	font-weight: 500;
	background-color: var(--bg);
	pointer-events: none;
	// z-index: 1;
}

/*.ava:not([src]){
	display: none;
}*/

.ava:not([src])::before,
.ava:is(:-moz-broken)::before {
	content: none !important;
}

//OPTION: if var(--bg) not support / style attr not set with JS
.ava[aria-label]:not([style])::after {
	font-size: 0.875rem;
	background-color: #55595c;
}

// OPTION: 
// .ava[aria-label]:-moz-broken{
//   opacity: 0;
// }

// .ava[aria-label="?"]::after{
// 	background-color: #55595c;
// }

