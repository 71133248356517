// ======= Helper =======
// Display
// == Flex behaviors ==
.flexno {
  flex: none !important;
}
.flex0 {
  flex: 0 !important;
}
.flex1 {
  flex: 1 !important;
}
.flex-auto {
  flex: auto !important;
}
// .flex1-auto {
//   flex: 1 1 auto !important;
// }
// END Flex
// END == Flex behaviors ==

// Grid
.d-grid {
  display: grid !important;
}
// END Grid

// END Display

// Typography
// .lh-inh
/**.q.lh-inh::before {
  line-height: inherit;
}*/

.lh-normal {
  line-height: normal;
}

.lh-1-25 {
  line-height: 1.25;
}

.lh-1-5 {
  line-height: 1.5;
}

.ws-pre {
  white-space: pre;
}

// .pre-wrap-before:before, // pre-wrap / pre-line for :before
/*.pre-wrap {
  white-space: pre-wrap;
}*/

.ws-normal {
  white-space: normal;
}

// .txt-outline {
//   text-shadow: 0.02em 0.02em 0 #215cff, -0.02em -0.02em 0 #215cff, -0.02em 0.02em 0 #215cff, 0.02em -0.02em 0 #215cff, 0 0.02em 0 #215cff, 0 -0.02em 0 #215cff, -0.02em 0 0 #215cff, 0.02em 0 0 #215cff;
// }
// END Typography

// == Sizing ==
.mxw-fit {
  max-width: fit-content !important;
}

// For .btn medium with fa equal width
.w-34px {
  width: 34px;
}

.min-h-75vh {
  min-height: 75vh;
}

// For main content metronic
.min-h-content {
  min-height: calc(100vh - 165px);
}

// .h-inherit {
//   height: inherit;
// }

// .mnw-auto {
//   min-width: auto;
// }

// .mxh-50vh {
//   max-height: 50vh;
// }
// == END Sizing ==

// == Spacing ==
.pr-5px {
  padding-right: 5px;
}

.ml-1px-next > * + * {
  margin-left: 1px !important;
}

.ml-1-next > * + * {
  margin-left: .25rem;
}

.ml-2-next > * + * {
  margin-left: .5rem;
}

.ml-3-next > * + * {
  margin-left: 1rem;
}

.ml-4-next > * + * {
  margin-left: 1.5rem;
}

.mt-1-next > * + * {
  margin-top: .25rem; //  !important
}

.mt-3-next > * + * {
  margin-top: 1rem;
}

// *.mx-min15 {
//   margin-right: -15px;
//   margin-left: -15px;
// }

.mx-min25 {
  margin-right: -25px;
  margin-left: -25px;
}
// == END Spacing ==

.select-no {
  user-select: none !important;
}

.select-auto {
  user-select: auto !important;
}

.ovhide {
  overflow: hidden !important;
}

.ovauto {
  overflow: auto !important;
}

.ovxhide {
  overflow-x: hidden !important;
}

.ovyhide {
  overflow-y: hidden !important;
}

.ovyauto {
  overflow-y: auto !important;
}
// .ovvisible{
//   overflow:visible !important;
// }
// .react-kanban-board,
.ovscroll,
.ovxscroll {
  overflow-x: scroll !important;
}

.ovscroll,
.ovyscroll {
  overflow-y: scroll !important;
}
// == Border ==
.bw-y1 {
  border-width: 1px 0 !important;
}

.bw-x1 {
  border-width: 0 1px !important;
}

/* .round-top {
  border-radius: .25rem .25rem 0 0 !important;
}

.round-bottom {
  border-radius: 0 0 .25rem .25rem !important;
}*/

.round-left-0 {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.round-right-0 {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

/**.resize-v {
  resize:vertical;
}

*.resize-no {
  resize:none;
}*/

// == Color ==
*.txt-inh {
  color: inherit;
}

.txt-navy {
  color: #0059a8;
}

.bg-strip {
  background: repeating-linear-gradient(-45deg,#ddd,#ddd 1px,#fff 3px,#fff 3px);
}

.bg-info-bs5 {
  background-color: #2fd3f4;
}

.bg-rgba-dark-1 {
  background-color: rgba(0,0,0,.3);
}

.bg-gradient-1 {
  background-image: linear-gradient(120deg, #a1c4fd 0%, #c2e9fb 100%);
}
// == END Color ==

// == Position ==
.t-0 {
  top: 0;
}
.t-50px {
  top: 50px;
}
.t-55px {
  top: 55px;
}
.l-0{
  left: 0;
}
.r-0{
  right: 0 !important;
}
.b-0 {
  bottom: 0;
}

// Calc with metronic subheader fix
.t-subheadfix {
  top: 119px;
}

.position-full {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
// == END Position ==

.cpoin {
  cursor: pointer !important;
}

.cauto {
  cursor: auto !important;
}

.cwait {
  cursor: wait !important;
}

.ant-spin, // antd <Spin />
.cprogress {
  cursor: progress !important;
}

.cdef {
  cursor: default;
}

.cinherit {
  cursor: inherit !important;
}
.chelp {
  cursor: help !important;
}
// fieldset[disabled],
// fieldset[disabled] *,// OPTION
.cnotallow {
  cursor: not-allowed !important;
}
// *.ns-resize,
// html.ns-resize *{
//   cursor:ns-resize !important;
// }
// *.ew-resize,
// html.ew-resize *{
//   cursor:ew-resize !important;
// }
*.row-resize,
html.row-resize *,
html.row-resize body:before {
  cursor: row-resize !important;
}

*.col-resize,
html.col-resize *,
html.col-resize body:before {
  cursor: col-resize !important;
}

html.row-resize *,
html.col-resize * {
  pointer-events: none !important;
}

html.row-resize .Resizer:active,
html.col-resize .Resizer:active {
  pointer-events: auto !important;
}

.cgrab {
  cursor: -webkit-grab !important;
  cursor: grab !important;
}

.cgrabbing,
html.cgrabbing * {
  cursor: -webkit-grabbing !important;
  cursor: grabbing !important;
}
// .react-draggable-transparent-selection::before, // === UI Builder / Designer ===
.cgrabbing {
  cursor: -webkit-grabbing !important;
  cursor: grabbing !important;
}

.ceresize {
  cursor: e-resize !important;
}

body.ceresize * {
  pointer-events: none !important;
}

body.ceresize .isResize {
  pointer-events: auto !important;
}
// == END Cursor ==
// == XUL ==
// .scroll-smooth{
//   scroll-behavior:smooth;
// }
.zi-1 {
  z-index: 1;
}

.zi-2 {
  z-index: 2;
}

.zi-3 {
  z-index: 3;
}

.ant-notification, // antd
.ant-modal-mask, 
.ant-modal-wrap, 
.zi-upModal {
  z-index: 1051;
}

.opacity-1 {
  opacity: 1 !important;
}

// fonts:
// === ICON SIZE With font-size ===
.text-sm {
  font-size: 0.75rem; // 0.875rem
}

.fs-100p {
  font-size: 100%;
}

.fs-h5 {
  font-size: 1.25rem !important;
}

.fs-25r {
  font-size: 2.5rem !important;
}

.fs-inherit {
  font-size: inherit !important;
}

.fw700 {
  font-weight: 700 !important;
}

.q-mr:not(:empty):before {
  margin-right: .5rem;
}

.q-mb-2:before {
  margin-bottom: .5rem;
}

.after-ml-auto::after {
  margin-left: auto !important;
}
// Only .form-control-sm
.dd-toggle-right::after {
  position: absolute;
  top: 13px;
  right: 9px;
}

.content-no:before {
  content: none !important;
}

// == Shadow ==
.shadow-1 {
  box-shadow: 0 2px 2px rgba(0,0,0,.15);
}

.shadow-2 {
  box-shadow: 0 0 1px 1px rgba(0,0,0,0.14), 0 0 2px 2px rgba(0,0,0,0.098), 0 0 5px 1px rgba(0,0,0,0.084);
}

.shadow-3 {
  box-shadow: 0 0 2px 1px rgba(255,255,255,0.4);
}
/*.shadow-3{
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
}*/

.shadow-4 {
  box-shadow: 0 .125rem .25rem rgba(0,0,0,.075) !important;
}

// .shadow-blue {
//   box-shadow: $btn-focus-box-shadow;
// }
// == END Shadow ==

// === SCROLL ===
// .fs-kanban-scrollhover .react-kanban-board, // Kanban
.fs-scroll {
  // overflow-x: hidden;
  // scroll-behavior: smooth;// OPTION
  overscroll-behavior: contain;
  // FOR Firefox
  scrollbar-width: thin;
  scrollbar-color: #aaa transparent;
  -webkit-overflow-scrolling: touch; // OPTION: From Bootstrap .table-responsive
}

// Kanban
// .fs-kanban-scrollhover .react-kanban-board::-webkit-scrollbar, 
.fs-scroll::-webkit-scrollbar {
  width: 0.4rem !important; // 0.4rem | 0.5rem
  height: 0.4rem !important;
}

.fs-scroll::-webkit-scrollbar-thumb {
  background-color: #ccc; // rgba(0, 0, 0, 0.2)
}

.fs-scroll::-webkit-scrollbar-thumb:hover {
  background-color: #aaa;
}

.fs-scroll::-webkit-scrollbar-thumb:active {
  background-color: #777;
}

.fs-scroll::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0.08); //  !important
}
// FOR Firefox
// Kanban
// .fs-kanban-scrollhover .react-kanban-board:not(:hover), 

.fs-scroll.scroll-hover:not(:hover) { // :not(:active)
  scrollbar-color: transparent transparent;
}
//   .fs-scroll.scroll-hover:hover{
//     scrollbar-color: #aaa transparent;
//   }

// Kanban
// .fs-kanban-scrollhover .react-kanban-board::-webkit-scrollbar-track, 
.fs-scroll.scroll-hover::-webkit-scrollbar-track {
  background-color: transparent;
}

// Kanban
// .fs-kanban-scrollhover .react-kanban-board:hover::-webkit-scrollbar-track, 
.fs-scroll.scroll-hover:hover::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0.08);
}

// Kanban
// .fs-kanban-scrollhover .react-kanban-board::-webkit-scrollbar-thumb, 
.fs-scroll.scroll-hover::-webkit-scrollbar-thumb {
  background-color: transparent;
}

// Kanban
// .fs-kanban-scrollhover .react-kanban-board:hover::-webkit-scrollbar-thumb, 
.fs-scroll.scroll-hover:hover::-webkit-scrollbar-thumb {
  background-color: #ccc;
}

// Kanban
// .fs-kanban-scrollhover .react-kanban-board::-webkit-scrollbar-thumb:hover, 
.fs-scroll.scroll-hover::-webkit-scrollbar-thumb:hover {
  background-color: #aaa; // bbb
}

// Kanban
// .fs-kanban-scrollhover .react-kanban-board > div::-webkit-scrollbar-thumb:active, 
// .fs-scroll.scroll-hover:active::-webkit-scrollbar-thumb,
.fs-scroll.scroll-hover::-webkit-scrollbar-thumb:active {
  background-color: #777;
}

.ovscroll-auto {
  overscroll-behavior: auto;
}

.scroller-x {
  flex-wrap: nowrap;
  overflow-x: auto;
  text-align: center;
  white-space: nowrap;
}
// === END SCROLL ===

// NavDrawer
.navDrawer .ant-drawer-content {
  background-color: rgba(255,255,255, .9);
}
// .navDrawer.ant-drawer-open .ant-drawer-mask
// .openNavDrawer .navDrawer .ant-drawer-mask{
//   height: calc(100% - var(--height)) !important;
//   top: auto;
//   bottom: 0;
// }

.icon-app {
  /*border: 1px solid #aaa;
	padding: 5px;*/
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 38px;
  // background-color: #cee6fd;
  color: #fff; // rgba(38, 143, 255, 0.91)
  // border: 3px solid rgba(38,143,255,.5);
  border: 4px solid transparent;
  // border-radius: 50%;
}

// Primary
.icon-app.primary {
  // box-shadow: 0 0 0 .2rem #0073B9; // #00A1D6
  border-color: #0073B9;
  background-color: #1991DB; // #55BFE2
}

// Secondary
// Dark : #0073B9
// Light: #1991DB

// Success
.icon-app.success {
  // box-shadow: 0 0 0 .2rem #07BF80;
  border-color: #07BF80;
  background-color: #15E29C;
}
// Danger
.icon-app.danger {
  // box-shadow: 0 0 0 .2rem #DE1C22; // rgba(38,143,255,.5)
  border-color: #DE1C22;
  background-color: #EE6161;
}
// Warning
.icon-app.warning {
  // box-shadow: 0 0 0 .2rem #FFBC00; // rgba(38,143,255,.5)
  border-color: #FFBC00;
  background-color: #F9CF5B;
}
// Info
.icon-app.info {
  // box-shadow: 0 0 0 .2rem#008197; // rgba(38,143,255,.5)
  border-color: #008197;
  background-color: #1EB2C9;
}

.btn-fs-icon.active {
  background-color: #e2e6ea;
}
// END NavDrawer

// Img / video
.of-cov {
  object-fit: cover;
}

.of-con {
  object-fit: contain;
}
// DEV OPTION: for draggable attribute in css
// .drag-no{
//   -webkit-user-drag: none;
//   -khtml-user-drag: none;
//   -moz-user-drag: none;
//   -o-user-drag: none;
//   user-drag: none;
//   -moz-window-dragging: no-drag;
// }
// END Img / video
.point-no {
  pointer-events: none; //  !important
}

.point-auto {
  pointer-events: auto !important;
}
// == Close / Remove button ==
.ired::before,
.xx:hover::before,
.close:hover {
  color: red;
}
// OPTION - draggable html native
// [draggable="true"]{
//   -moz-user-select: none;
//   -khtml-user-select: none;
//   -webkit-user-select: none;
//   user-select: none;
//   // Required to make elements draggable in old WebKit
//   -khtml-user-drag: element;
//   -webkit-user-drag: element;
// }
// OPTION - disabled draggable html native
// [draggable="false"]{
//   -webkit-user-select: none;
//   -khtml-user-select: none;
//   -moz-user-select: none;
//   -o-user-select: none;
//   user-select: none;
// }

// // MOVE TO SPECIFIC Component Name
// .hr-v, 
.hr-h {
  display: flex;
  align-items: center;
  /*white-space: nowrap;*/
  height: auto;
  line-height: 1;
  text-align: center;
  letter-spacing: .05em;
}

/*.hr-v {
  flex-direction: column;
}*/

// .hr-v::before,
// .hr-v::after, 
.hr-h::before,
.hr-h::after {
  content: "";
  display: flex;
  position: relative;
  flex: 1 1;
  /*border-top: 2px solid #dee2e6;*/ // eee
}

.hr-h::before,
.hr-h::after {
  border-top: 2px solid #dee2e6;
}

.hr-h::before {
  margin-right: 9px; // 7px
}
.hr-h::after{
  margin-left: 9px;
}

/*.hr-v::before,
.hr-v::after {
  width: 2px;
  background-color: #ddd;
}

.hr-v::before {
  margin-bottom: 9px;
}
.hr-v::after {
  margin-top: 9px;
}*/

// Brand Color:
*.bc-microsoft {
  background-color: #329ffd;
}

*.btn.bc-microsoft:hover {
  background-color: #0089ff;
}

// Symbols:
// ROLE ACCESS
.pie {
  display: inline-block;
  user-select: none;
}

.pie.spinner-border,
.pie:before {
  width: 18px;
  height: 18px;
}

.pie:before {
  content: "";
  display: inline-block; // block
  border-radius: 50%;
  background-color: green;
  border: 2px solid green;
  margin-left: auto;
  margin-right: auto;
  vertical-align: middle;
}

.pie.spinner-border:before {
  content: none;
}

// .pie.ten:before
.pie.pie-no-access:before {
  border: 2px solid #cc0d0d;
  background-color: transparent;
  background-image: linear-gradient(90deg, transparent 50%, white 50%), linear-gradient(90deg, white 50%, transparent 50%);
}

// .pie.twentyfive:before
.pie.pie-own-record:before {
  background-color: #ffca00;
  border: 2px solid #ffca00;
  background-image: linear-gradient(52.5deg, transparent 50%, white 50%), linear-gradient(127.5deg, white 50%, transparent 50%);
  /*background-image: linear-gradient(180deg, white 50%, white 70%, green 70%);*/
}

// .pie.fifty:before
.pie.pie-businessunit:before {
  background-color: #ffca00;
  border: 2px solid #ffca00;
  background-image: linear-gradient(180deg, white 50%, transparent 50%);
}

// .pie.seventyfive:before
.pie.pie-parent-child:before {
  background-image: linear-gradient(232.5deg, transparent 50%, green 50%), linear-gradient(127.5deg, white 50%, transparent 50%);
}

// .pie.onehundred:before
.pie.pie-organization:before {
  background-image: none;
}

// EVENT EFFECT:
.pie.cpoin:hover:before {
  box-shadow: 0 0 0 0.2rem rgba(38,143,255,.5);
}

.pie.cpoin:active:before {
  box-shadow: 0 0 0 0.2rem #007bff;
}

.img-frame.ant-skeleton {
  width: auto;
}

// 
.ratio-1-1 {
  aspect-ratio: 1 / 1;
}

.ratio-4-3 {
  aspect-ratio: 4 / 3;
}

.ratio-16-9 {
  aspect-ratio: 16 / 9;
}

.ratio-21-9 {
  aspect-ratio: 21 / 9;
}

// OVERRIDE button html
.fs-btn {
  display: inline-block;
  background-color: transparent;
  user-select: none;
}

.ant-select.is-invalid > .ant-select-selector {
  border-color: #F64E60 !important;
  // box-shadow: none;
}

// Stimulsoft
.fs_stimulsoft:not(.refresh) #StiViewerReportPanel + div {
  position: static !important;
}

.fs_stimulsoft .stiDesignerToolBar, 
.fs_stimulsoft .stiDesignerStatusPanel,
.fs_stimulsoft .stiDesignerToolButton_Mouse {
  background: #0079BD;
}

// .stiJsViewerToolBar 
.fs_stimulsoft .stiJsViewerToolBarTable {
  padding-right: 2rem;
}

// .fs_stimulsoft .stiJsViewerToolBarTable > tbody > tr > td:nth-child(1) img
//  tr.stiJsViewerClearAllStyles 

// .fs_stimulsoft .stiJsViewerToolBarTable img, 
// .fs_stimulsoft .stiJsViewerDashboardElementButtonsPanel > .stiJsViewerToolBarTable > tbody > tr > td td + td, 
// .fs_stimulsoft .stiJsViewerToolBarTable > tbody > tr > td:nth-child(2) > table > tbody > tr > td > div td + td {
//   display: none !important;
// }

// .fs_stimulsoft .stiJsViewerDashboardElementButtonsPanel > .stiJsViewerToolBarTable > tbody > tr > td td:before, 
// .fs_stimulsoft .stiJsViewerToolBarTable > tbody > tr > td:nth-child(2) > table > tbody > tr > td > div td:before {
//   -moz-osx-font-smoothing: grayscale;
//   -webkit-font-smoothing: antialiased;
//   display: inline-block;
//   font-style: normal;
//   font-feature-settings: normal;
//   font-variant: normal;
//   text-rendering: auto;
//   line-height: 1;
//   font-family: "Font Awesome 5 Pro";
//   font-weight: 900;
//   text-align: center;
//   width: 1.5em; // 1.25em
// }

// .fs_stimulsoft .stiJsViewerToolBarTable > tbody > tr > td:nth-child(2) > table > tbody > tr > td:nth-child(1) > div td:before {
//   content: "\f01e";
// }

// .fs_stimulsoft .stiJsViewerToolBarTable > tbody > tr > td:nth-child(2) > table > tbody > tr > td:nth-child(3) > div td:before {
//   content: "\f07c";
// }

// .fs_stimulsoft .stiJsViewerToolBarTable > tbody > tr > td:nth-child(2) > table > tbody > tr > td:nth-child(4) > div td:before {
//   content: "\f303";
// }

// .fs_stimulsoft .stiJsViewerDashboardElementButtonsPanel > .stiJsViewerToolBarTable > tbody > tr > td:nth-child(4) td:before,
// .fs_stimulsoft .stiJsViewerToolBarTable > tbody > tr > td:nth-child(2) > table > tbody > tr > td:nth-child(5) > div td:before {
//   content: "\f065";
// }

// .fs_stimulsoft .stiJsViewerDashboardElementButtonsPanel > .stiJsViewerToolBarTable > tbody > tr > td:nth-child(6) td:before, 
// .fs_stimulsoft .stiJsViewerToolBarTable > tbody > tr > td:nth-child(2) > table > tbody > tr > td:nth-child(6) > div td:before {
//   content: "\f56d";
// }

// .fs_stimulsoft .stiJsViewerDashboardElementButtonsPanel > .stiJsViewerToolBarTable > tbody > tr > td:nth-child(1) td:before {
//   content: "\f883";
// }

// .fs_stimulsoft .stiJsViewerDashboardElementButtonsPanel > .stiJsViewerToolBarTable > tbody > tr > td:nth-child(5) td:before {
//   content: "\f865";
// }






