// 
a:not([disabled]),
select:not([disabled]),
.dropdown-item:not(.disabled):not(:disabled),
.nav-link:not(.disabled),
// [role="button"]:not(.disabled),
.list-group-item-action:not(.disabled),

::-webkit-file-upload-button,
.custom-file-input, 
.custom-file-label{
	cursor: pointer;
}

// :not(.SplitPane)
// :disabled,
*:not(button).disabled{
  pointer-events: none;
}

.nav-link {
  color: $dark;
}

.link-sm .nav-link {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

button.nav-link{
  border: 0;
}
button.nav-link:not(:hover) {
  background-color: transparent;
}

.btn-light,
.btn-light:hover,
.btn-light.disabled,
.btn-light:disabled{
	border-color: #ccc;
}

.btn-group .btn-secondary:not(:hover){
  border-color: #aaa;
}

// Active .btn in btn-group / from parent
.open > .btn{
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

// == Custom button ==
.btn-flat{
  background-color:transparent;
  border-color:transparent;
  color:inherit;
}
// .btn-flat.active,
.dropdown.show > .btn-flat, // <- dropdown menu open
.btn-flat.ant-popover-open,
// .btn-flat:focus,
.btn-flat:not([disabled]):hover{
  background-color:#e2e6ea;
}

a.disabled{
  pointer-events: none;
}
.btn[disabled]{
  cursor: not-allowed;
}

label{
  font-family: inherit !important;
}

label.btn,
label.dropdown-item{
	margin-bottom: 0;
}

.custom-switch,
.custom-switch label{
  // display: inline-block;
  user-select: none;
  cursor: pointer;
}

.custom-switch{
  display: inline-flex;
  align-items: center;
}

// OPTION
// === textarea ===
textarea{
  min-height: calc(1.5em + 0.75rem + 2px);
}
textarea.form-control-lg{
  min-height: calc(1.5em + 1rem + 2px);
}
textarea.form-control-sm{
  min-height: calc(1.5em + .5rem + 2px);
}

label.form-group{
  display:block;
}

// Button
// CUSTOM btn size
.btn-group-xs > .btn,
.btn-xs {
  padding: 1px 5px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}

.navbar-toggler:focus{
  outline: none;
}
a.navbar-toggler span{
	pointer-events: none;
}

.i.badge{
  display: inline-block;
}

// == dropdown-menu ==
.dropdown-toggle:empty{
  margin-left: 0;
}

.dropdown-item{
  padding:.25rem 1rem;
}

.nav-link:hover,
.dropdown-item:not(:active):not(.active):hover{
  background-color: $hover-color;
}

.navbar .nav-link:hover{
  background-color: inherit;
}

.nav-link:active {
  background-color: $primary !important;
  color: #fff !important;
}

.nav-link:focus {
  box-shadow: $btn-focus-box-shadow;
}

.dropdown-menu > hr{
  margin:4px 0;
}

// == form-control ==
.form-control.fal:before{
  color: #ccc;// $text-muted
  margin-right: .5rem;
}

// .form-check,
// .custom-control,
.nav-link{
  user-select:none;
}
// DEV OPTION - For checkbox custom in dropdown-item
.custom-control-label{
  display:inline-block;
}
label.dropdown-item .custom-control-label{
  pointer-events: none;
}
// == END form-control ==

// OPTION: For Performance & Accessibility
// https://developer.mozilla.org/en-US/docs/Web/CSS/position
/*.position-sticky {
  will-change: transform;
}*/

// === modal ===
// Disabled transition when modal open / transform animate in windows 10
.modal.show.fade .modal-dialog {
  transform: none !important;
}

.modal-full .modal-dialog {
  max-width: 100%;
  // margin-top: 0;
  // margin-bottom: 0;
  margin: 0;
}

.modal-full .modal-content {
  border: 0;
  border-radius: 0;
}

.modal-full-2 .modal-dialog{
  max-width: calc(100% - 12px);
  margin-top: 6px;
  margin-bottom: 6px;
}
.modal-full-2 + .modal-backdrop{
//   background-color: #999;
//   opacity: 1;
  opacity: .75;
}

.modal-full-2 .modal-content{
  min-height: calc(100vh - 12px);
}

// .modal-down.modal{
//   display: flex !important;
//   overflow: hidden;
// }
// .modal-down .modal-dialog{
//   margin-top: 0;
//   margin-bottom: 0;
//   align-self: flex-end;
//   width: inherit;
// }
// .modal-down.fade:not(.show) .modal-dialog{
//   transform: translateY(50px);
// }
// .modal-down .modal-content{
//   border-bottom: 0;
//   border-bottom-right-radius: 0;
//   border-bottom-left-radius: 0;
// }
// === END modal ===

// == custom-control ==
label.custom-control{
  margin-bottom:0;
}

// == badge ==
.badge.q{
  display:inline-block;
}
.badge.cpoin{
  user-select:none;
}

// == form-control / custom-select ==
.form-control:not(textarea){
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.form-control:disabled,
.custom-select:disabled{
  cursor:not-allowed;
}

*:focus{
  outline:none !important;
}

// input-group
/*.input-append:focus + .input-group-append > .input-group-text {
  border-color: #69b3ff;
}*/

