/* FOR Tablet Landscape */
@media (max-width: 1199px) and (min-width: 992px){
	/*html,
	body,
	#root {
		height: auto; //  !important
		// OPTION: FOR "bounce" effects or refreshes
		// https://developer.mozilla.org/en-US/docs/Web/CSS/overscroll-behavior
		overscroll-behavior: none; // contain
	}*/

	.lockScroll{
		overflow:hidden !important;
	}
	
	// Stimulsoft
  .FSALogo {
		position: static !important;
	}
	
}/* END FOR Tablet Landscape */

// Large Device:
@media (min-width:768px){
	// Helpers:
	.ws-pre-pc {
		white-space: pre;
	}
}